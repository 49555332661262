import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { GET_AI_CONTROL } from '../../utility-functions/gqlQueries';
import { CircularProgress, makeStyles, Tab, Tabs } from '@material-ui/core';
import * as colors from '../../colors';
import AiOverview from './AiOverview';

const useStyles = makeStyles({
	root: {
		color: '#888',
		fontWeight: 900,
		minWidth: 6 + 'rem',
		textTransform: 'none',
		fontSize: '108%',
		padding: '6px ' + 12 + 'px',
	},
	selected: {
		color: colors.bgcolor,
	},
});

function CustomTabPanel({ children, value, index, ...props }) {
	return (
		<div role='tabpanel' hidden={value !== index} id={`system-tabpanel-${index}`} aria-labelledby={`system-tab-${index}`} {...props}>
			{value === index && children}
		</div>
	);
}

function AiFolderWrapper({ property }) {
	const [aiControls, setAiControls] = useState();
	const [tabIndex, setTabIndex] = useState(0);
	const [tabs, setTabs] = useState([]);

	const { loading, error } = useQuery(GET_AI_CONTROL, {
		variables: { filter: { locationid: [String(property)] } },
		onCompleted: ({ getAiControls: data }) => {
			const dataOrderedBySystemId = data.reduce((acc, control) => {
				if (control.system == null) return acc;
				const system = control.system;
				if (acc[system]) acc[system].push(control);
				else acc[system] = [control];
				return acc;
			}, {});
			const correctOrderedIds = Object.keys(dataOrderedBySystemId).sort();
			setAiControls(correctOrderedIds.map(id => dataOrderedBySystemId[id]));
			setTabs(correctOrderedIds.map(id => ({ id: id, label: `System: ${id}` })));
		},
	});

	if (loading)
		return (
			<div style={{ display: 'flex', justifyContent: 'center', padding: '5rem' }}>
				<CircularProgress />
			</div>
		);

	if (error) return <div>Error: {error.message}</div>;

	return (
		<div>
			<Tabs value={tabIndex} onChange={(event, index) => setTabIndex(index)}>
				{tabs.map((tab, i) => (
					<Tab key={tab.id} label={tab.label} id={`system-tab-${i}`} aria-controls={`system-tabpanel-${i}`} />
				))}
			</Tabs>
			{tabs.map((tab, i) => (
				<CustomTabPanel key={tab.id} value={tabIndex} index={i}>
					<AiOverview property={property} aiControls={aiControls[i]} />
				</CustomTabPanel>
			))}
		</div>
	);
}

export default AiFolderWrapper;
